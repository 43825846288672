@if (!poll) {
  <app-spinner></app-spinner>
}
@if (poll) {
  <div class="poll-editor-container">
    <div class="notice">
      <app-notice></app-notice>
    </div>
    @if (poll) {
      <div class="poll-editor">
        <tyt-image-asset
          [(url)]="poll.image"
          storageMethod="data-url"
          [aspectRatio]="16/9"
        ></tyt-image-asset>
        <mat-tab-group mat-stretch-tabs="false">
          <mat-tab label="Content">
            <div class="form-layout">
              @if (showTitleField) {
                <mat-form-field appearance="outline">
                  <mat-label>{{typeLabels.title}}</mat-label>
                  <input matInput class="title" type="text" placeholder="Enter {{typeLabels.title}}" maxlength="660" name="title"
                    [(ngModel)]="poll.title" />
                  </mat-form-field>
                }
                @if (poll.type === 'Petition' && poll.questions && poll.questions.length > 0) {
                  <mat-form-field appearance="outline" >
                    <mat-label>{{typeLabels.caption}}</mat-label>
                    <input matInput type="text" class="caption" name="caption" [(ngModel)]="poll.questions[0].body" />
                  </mat-form-field>
                }
                @if (poll.type === 'Petition') {
                  <div>
                    <h1>About This Petition</h1>
                  </div>
                }
                @if (showDescriptionField) {
                  <div class="form-row">
                    <mat-form-field appearance="outline">
                      <mat-label>{{typeLabels.description}}</mat-label>
                      <textarea
                        matInput
                        autosize
                        class="poll-form-style description"
                        name="description"
                        [(ngModel)]="poll.description"
                      ></textarea>
                    </mat-form-field>
                  </div>
                }
                <div class="form-row">
                  @if (byStaff && allowUserSelection) {
                    <mat-form-field appearance="outline">
                      <mat-label>Username</mat-label>
                      <input matInput type="text" name="username" [(ngModel)]="poll.username" />
                    </mat-form-field>
                  }
                  @if (allowTopicSelection) {
                    <mat-form-field appearance="outline">
                      <mat-label>Topic</mat-label>
                      <mat-select [(ngModel)]="poll.topic_id" name="topic">
                        <mat-option [value]="null">None</mat-option>
                        @for (topic of topics; track topic) {
                          <mat-option [value]="topic.slug">
                            {{topic.name}}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Publishing">
              @if (showSlugField) {
                <div class="settings-row">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>URL Slug</mat-label>
                    <input matInput placeholder="URL slug" [(ngModel)]="pollSlug" (blur)="conformSlug()" #slugField>
                  </mat-form-field>
                </div>
              }
              <div class="settings-row">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>
                    @if (poll.type === 'Petition') {
                      Publishes At:
                    } @else {
                      Opens At:
                    }
                  </mat-label>
                  <tyt-datetime-picker [(ngModel)]="poll.opens_at"></tyt-datetime-picker>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Closes At:</mat-label>
                  <tyt-datetime-picker [(ngModel)]="poll.closes_at"></tyt-datetime-picker>
                </mat-form-field>
              </div>
              <div class="settings-row">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Feature At:</mat-label>
                  <tyt-datetime-picker [(ngModel)]="poll.featured_at"></tyt-datetime-picker>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>Expire Feature At:</mat-label>
                  <tyt-datetime-picker [(ngModel)]="poll.featured_expires_at"></tyt-datetime-picker>
                </mat-form-field>
              </div>
              <div class="settings-row">
                @if (canVotesBeChanged) {
                  <div>
                    <label>
                      <input type="checkbox" [(ngModel)]="poll.vote_changeable" /> Votes are changeable
                    </label>
                  </div>
                }
                @if (canRequireMembershipToVote) {
                  <div>
                    <label>
                      <input type="checkbox" [(ngModel)]="poll.membership_required" /> Require membership to vote
                    </label>
                  </div>
                }
              </div>
              @if (poll.type !== 'Petition') {
                <div class="settings-row">
                  <div>
                    <label>
                      <input type="checkbox" [(ngModel)]="poll.hide_results_before_voting" /> Hide results before voting
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="checkbox" [(ngModel)]="poll.hide_results_after_voting" /> Hide results after voting
                    </label>
                  </div>
                </div>
              }
            </mat-tab>
            @if (canHaveMissions && enableMissions) {
              <mat-tab label="Mission">
                @if (poll.active_mission) {
                  @if (isPersisted) {
                    <div style="text-align: right; margin-bottom: 1em;">
                      <button type="button" mat-raised-button color="primary" (click)="createNewMission()">
                        <mat-icon>sync</mat-icon>
                        Start a new mission
                      </button>
                    </div>
                  }
                  <div class="form-layout">
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Post Copy</mat-label>
                      <textarea name="post_copy" matInput [(ngModel)]="poll.active_mission.post_copy"></textarea>
                    </mat-form-field>
                    <br/>
                    <div class="hint">
                      Use <code>PETITION_URL</code> to refer to the URL of the petition itself. This should be the first URL in
                      the post for the best effect. If you do not include it in the post, it will be appended to the end of the
                      post for you.
                    </div>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Video URL</mat-label>
                      <input name="video_url" matInput [(ngModel)]="poll.active_mission.video_url">
                    </mat-form-field>
                    <div class="hint">
                      Video may be a YouTube URL or an HTML5 compatible media URL (.mp4)
                    </div>
                    <tyt-image-asset
                      [(url)]="poll.active_mission.image_url"
                      storageMethod="data-url"
                      [aspectRatio]="16/9"
                    ></tyt-image-asset>
                    <fieldset>
                      <legend>Share Targets</legend>
                      <mat-checkbox name="share_target_twitter" [(ngModel)]="shareTargets.twitter">Twitter</mat-checkbox>
                      <mat-checkbox name="share_target_facebook" [(ngModel)]="shareTargets.facebook">Facebook</mat-checkbox>
                      <mat-checkbox name="share_target_reddit" [(ngModel)]="shareTargets.reddit">Reddit</mat-checkbox>
                    </fieldset>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label>Brandwatch Query ID</mat-label>
                      <input name="brandwatch_query_id" matInput [(ngModel)]="poll.active_mission.brandwatch_query_id">
                    </mat-form-field>
                    <div class="hint">Enter the ID for the Query that was created within Brandwatch for this Petition Mission. Can leave blank if Reach information is not required for this campaign.</div>
                  </div>
                } @else {
                  <div class="empty-state">
                    <p>Missions let you call supporters to action after signing. Tap New Mission below to get started.</p>
                    <button mat-raised-button color="primary" type="button" (click)="createNewMission()">
                      <mat-icon>add</mat-icon>
                      New Mission
                    </button>
                  </div>
                }
              </mat-tab>
            }
            @if (poll.type === 'Petition') {
              <mat-tab label="Goal &amp; Milestones">
                <div class="empty-state">
                  <p>Provide a signature goal for supporters to achieve to incentivize them to spread the word.</p>
                </div>
                <div class="settings-row">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Signature Goal</mat-label>
                    <input matInput class="goal" type="number" placeholder="0" min="0" step="1"
                      name="goalAmount" [(ngModel)]="goalAmount" />
                    </mat-form-field>
                  </div>
                  <mat-divider></mat-divider>
                  @if (milestones.length === 0) {
                    <div class="empty-state">
                      <p>Milestones let you lay out several goals in advance in a concise visual presentation.</p>
                      <a mat-button href="javascript:;" (click)="addMilestone()">
                        <mat-icon>add</mat-icon>
                        Add Milestone
                      </a>
                    </div>
                  }
                  @if (milestones.length > 0) {
                    <div class="empty-state">
                      @for (milestone of milestones; track milestone; let i = $index) {
                        <div class="milestone">
                          <mat-form-field class="label" appearance="outline" floatLabel="always">
                            <mat-label>Label</mat-label>
                            <input matInput type="text" class="goal" maxlength="40" placeholder="Enter a label"
                              [(ngModel)]="milestone.label" />
                            </mat-form-field>
                            <mat-form-field class="percent" appearance="outline" floatLabel="always">
                              <mat-label>Percentage</mat-label>
                              <input matInput class="goal" type="number" min="0" max="100" placeholder=""
                                [(ngModel)]="milestone.percentage" />
                                <span matSuffix>%</span>
                              </mat-form-field>
                              <a mat-icon-button (click)="deleteMilestone(i)">
                                <mat-icon>close</mat-icon>
                              </a>
                            </div>
                          }
                          <a mat-button href="javascript:;" (click)="addMilestone()">
                            <mat-icon>add</mat-icon>
                            Add Milestone
                          </a>
                        </div>
                      }
                    </mat-tab>
                  }
                  @if (poll?.type === 'Petition') {
                    <mat-tab label="Endorsements">
                      <tyt-endorsements-editor
                        [endorsements]="poll.endorsements"
                        (changed)="updateEndorsements($event)"
                      ></tyt-endorsements-editor>
                    </mat-tab>
                  }
                  @if (poll?.type == 'Petition' && user?.staff) {
                    <mat-tab label="Offer Set">
                      <div class="empty-state">
                        <p>Attach an offer set to encourage supporters to consider TYT membership.</p>
                      </div>
                      <div class="form-layout">
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label>Offer Set</mat-label>
                          <mat-select [(ngModel)]="poll.offer_set_id">
                            <mat-option [value]="null">None</mat-option>
                            @for (offerSet of embeddedOffers; track offerSet) {
                              <mat-option [value]="offerSet.id">
                                {{offerSet.name}}
                              </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                        <small>When enabled, this offer set will be presented to users after they have completed signing
                        the petition.</small>
                      </div>
                    </mat-tab>
                  }
                  @if (poll && poll.type !== 'Petition') {
                    <mat-tab label="Email">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Klaviyo Optin List ID</mat-label>
                        <input matInput type="text" placeholder="Klaviyo Optin List" maxlength="660" name="klaviyo_optin_list_id"
                          [(ngModel)]="poll.klaviyo_optin_list_id" />
                        </mat-form-field>
                        <br/>
                        <small>
                          The list you use should have a name that is suitable for voters to see. If a voter has suppressed
                          marketing emails they may receive a re-optin email from Klaviyo that exposes the name of the list.
                        </small>
                        <br/>
                        <br/>
                      </mat-tab>
                    }
                  </mat-tab-group>
                  @if (canHaveQuestions) {
                    <div class="questions" cdkDropList (cdkDropListDropped)="reorderQuestions($event)">
                      @for (question of poll.questions; track question) {
                        <div class="poll-question" [class.hidden]="question['_destroy']" cdkDrag
                          [cdkDragDisabled]="isPublished || undeletedQuestionCount <= 1">
                          <div class="question-toolbar">
                            @if (!isPublished && undeletedQuestionCount > 1) {
                              <a mat-icon-button href="javascript:;" cdkDragHandle>
                                <mat-icon>menu</mat-icon>
                              </a>
                            }
                            <mat-form-field appearance="outline">
                              <mat-label>Question</mat-label>
                              <textarea
                                matInput
                                cdkTextareaAutosize
                                class="poll-form-style body"
                                [(ngModel)]="question.body"
                                [readonly]="isPublished"
                                placeholder="Enter {{typeLabels.question}}"
                              ></textarea>
                            </mat-form-field>
                            <mat-form-field appearance="outline" floatLabel="always" style="width: 8em; flex-grow: 0;">
                              <mat-label>Max Choices</mat-label>
                              <input
                                type="number"
                                matInput
                                name="max_choices_count"
                                min="1"
                                [(ngModel)]="question.max_choices_count"
                                [readonly]="isPublished"
                                title="How many can a voter choose?"
                                />
                            </mat-form-field>

                            <mat-checkbox matTooltip="Randomize the order of choices" [(ngModel)]="question.randomize_choices">Randomize</mat-checkbox>

                            @if (undeletedQuestionCount > 1) {
                              <a mat-icon-button class="delete" href="javascript:;"
                                (click)="removeQuestion(question)">
                                <mat-icon>delete</mat-icon>
                              </a>
                            }
                          </div>
                          <div class="options" cdkDropList (cdkDropListDropped)="reorderOptions($event, question)">
                            @for (option of question.choices; track option) {
                              <div class="poll-option" [class.hidden]="option['_destroy']" cdkDrag>
                                <div class="option-handle">
                                  <a mat-icon-button href="javascript:;" cdkDragHandle>
                                    <mat-icon>menu</mat-icon>
                                  </a>
                                  @if (!isPublished) {
                                    <a mat-icon-button href="javascript:;" (click)="removeOption(question, option)">
                                      <mat-icon>delete</mat-icon>
                                    </a>
                                  }
                                </div>
                                <tyt-image-asset
                                  [(url)]="option.image"
                                  storageMethod="data-url"
                                  [aspectRatio]="1/1"
                                ></tyt-image-asset>
                                <mat-form-field appearance="outline">
                                  <mat-label>Choice</mat-label>
                                  <textarea
                                    matInput
                                    cdkTextareaAutosize
                                    [readonly]="isPublished"
                                    class="poll-form-style"
                                    [(ngModel)]="option.body"
                                  ></textarea>
                                </mat-form-field>
                              </div>
                            }
                          </div>
                          <div style="text-align: center;">
                            @if (!isPublished && question.choices.length < maxChoices) {
                              <a mat-raised-button color="primary" href="javascript:;" (click)="addOption(question)"
                                >
                                <mat-icon>add</mat-icon>
                                Choice
                              </a>
                            }
                          </div>
                        </div>
                      }
                    </div>
                  }
                  @if (canHaveQuestions) {
                    <div style="text-align: center;">
                      @if (!isPublished && undeletedQuestionCount < maxQuestions) {
                        <button class="btn" (click)="addQuestion()">
                          <mat-icon>add</mat-icon>
                          Question
                        </button>
                      }
                    </div>
                  }
                  <div class="spacer"></div>
                </div>
              }
            </div>
          }
